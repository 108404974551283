




import Component, { mixins } from 'vue-class-component'
import Slide from '@/mixins/Slide'
import ErrorScreen from '@/components/ErrorScreen.vue'
import HttpError from '@/errors/HttpError'

@Component({
    components: {
        ErrorScreen
    }
})
export default class SlideNotFound extends mixins(Slide) {
    get error() {
        return new HttpError('SlideNotFound', 404)
    }
}
